
import { useQuery } from "@tanstack/react-query";
import { getCardFilters } from "../api/Api";

export default function useGetCardFilters(token, id) {
    return useQuery({
        queryKey:['card_filters'],
        queryFn:()=>getCardFilters(token, id),
        enabled:!!id
    })
}
