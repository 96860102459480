import axios from "axios"
import { BASE_URL } from "../static/static"

export const getConsolidatedBalance = async (token) => {
    console.log('getCOnsolidateBalance', token)
    const response = await axios.get(`${BASE_URL}/consolidated_balance?target_currency=USD`,{
        headers: {
            "Authorization": token
        }
    })
    return response.data
}
export const getUserProfile = async (token) => {
    console.log('getUserProfile workws',)
    const response = await axios.get(`${BASE_URL}/profile`,{
        headers: {
            "Authorization": token
        }
    })
    return response.data
}

export const getBalances = async (token) => {
    console.log('getBalances workws',)
    const response = await axios.get(`${BASE_URL}/balances`,{
        headers: {
            "Authorization": token
        }
    })
    return response.data
}

export const getWithdrwals = async (token) => {
    console.log('getHistory workws',)
    const response = await axios.get(`${BASE_URL}/withdrawals`,{
        headers: {
            "Authorization": token
        }
    })
    return response.data
}

export const getServices = async (token) => {
    console.log('getServices workws',)
    const response = await axios.get(`${BASE_URL}/services`,{
        headers: {
            "Authorization": token
        }
    })
    return response.data
}

export const getCardFilters = async (token, id) => {
    console.log('getCardFilters workws',)
    const response = await axios.get(`${BASE_URL}/available_service_cards?service_id=${id}`,{
        headers: {
            "Authorization": token
        }
    })
    return response.data
}
export const getUserProfilePic = async (token) => {
    console.log('getUserProfilePic works')
    const response = await axios.get(`${BASE_URL}/user_photo`,
        {
            responseType: "blob",
            headers: {
                "Authorization": token
            }
        })
        return response.data
}

export const getLastTransactions = async (token, status, page, range, currency) => {
    console.log('getLastTransactions workws',)
    console.log('Last transactions body = ', {token:token,status:status,page:page, range:range, currency:currency})
    const response = await axios.get(`${BASE_URL}/last_transactions?page=${page}${status !== null ? `&status=${status}` : ``}${range.length > 0 ? `&start_date=${range[0]}&end_date=${range[1]}` : ''}${currency !== 'All' ? `&currency=${currency}` : ''}`,{
        headers: {
            "Authorization": token
        }
    })
    return response.data
}

export const getCurrencies = async (token) => {
    console.log('getCurrencies workws',)
    const response = await axios.get(`${BASE_URL}/currencies`,{
        headers: {
            "Authorization": token
        }
    })
    return response.data
}
export const getInvoices = async (token, id) => {
    console.log('getInvoices workws',)
    const response = await axios.get(`${BASE_URL}/invoices${id ? `?invoice_id=${id}`:''}`,{
        headers: {
            "Authorization": token
        }
    })
    return response.data
}

export const postUserProfile = async (token, data) => {
    console.log('postUser')
    const response = await axios.post(`${BASE_URL}/profile`, data, {
        headers:{
            "Authorization" : token
        }
    })
    return response.data
}