import { useQuery } from "@tanstack/react-query"
import { getInvoices } from "../api/Api"

export const useGetInvoices = (token) => {
    return useQuery({
        queryKey:['invoice'],
        queryFn:()=>getInvoices(token),
        enabled:!!token
    })
}

export const useGetInvoiceById = (token, id) => {
    return useQuery({
        queryKey:['invoiceById', id],
        queryFn:()=>getInvoices(token,id),
        enabled:!!token
    })
}