
import {create} from 'zustand';

export const useSortCurrency = create((set) => ({
    currency:'All',
    setSortCurrency:(type) => set(() => ({currency:type}))
}))

export const useSortCurrencyInvoice = create((set) => ({
    currencyInvoice:'All',
    setSortCurrencyInvoice:(type) => set(() => ({currencyInvoice:type}))
}))