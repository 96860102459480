import React, { useContext, useState } from 'react';
import { useAuthToken } from '../../zustand/authStore';
import { useGetInvoices } from '../../hooks/useGetInvoices';
import "../invoicePage/InvoicePage"
import ActivetyPlateForInvoices from '../../components/activietyPlateForInvocies/ActivetyPlateForInvoices';
import { useSortTransStore } from '../../zustand/sortTransStore';
import { AppState } from '../../routes/Root';
import PaymentInvoce from '../../components/paymentDone/PaymentInvoce';
import PaymentAllInvoices from '../../components/paymentDone/PaymentForAllInvoices';
import ValueFilter from '../../components/statusFilter/valueFilter';
import { useSortCurrencyInvoice } from '../../zustand/sortValues';
import Loading from '../../components/loader/Loading'
import NoTransactions from '../../components/loader/NoTransactions'
const AllInvoices = () => {
    const context = useContext(AppState)
    const {token} = useAuthToken((state)=>state)
    const {currencyInvoice} = useSortCurrencyInvoice((state)=>state)
    const {data, isLoading} = useGetInvoices(token )
    
    const filteredArray = data?.data?.filter(el=>currencyInvoice === el.payment_system || currencyInvoice === "All")
    
    console.log(filteredArray)
     return (
        <>
        {context.data.invose ?
            <PaymentAllInvoices /> : null
        }
        <div className="mainFrame-invoice-formation block">
            <div className="mainFrame-invoice-formation_title">
                <h1 className="mainFrame-invoice-formation_title">
                    All Invoices
                </h1>
            </div>
            <ValueFilter/>
            {
                
                data ?
                data.data &&
                <div className="mainFrame_lastActiviti-body">
                        {data.data.length > 0 ?
                            filteredArray.map((element, index) => {
                                return <ActivetyPlateForInvoices key={index} data={element} />
                            })
                            : <h4 style={{alignSelf:'flex-start', paddingLeft:'20px'}}>No invoices yet</h4>
                        }
                </div>
                :
                isLoading ?
                <Loading/> :
                <NoTransactions/>
            }
        </div>
            </>
    );
}

export default AllInvoices;
