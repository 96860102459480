import React from 'react';
import './ErrorPage.css'
const ErrorPage = () => {
    return (
        <div className='page'>
            <h1>AUTH ERROR</h1>
        </div>
    );
}

export default ErrorPage;
