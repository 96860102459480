import React, { useState } from 'react';
import { useSortCurrencyInvoice } from '../../zustand/sortValues';

const ValueFilter = ({setSelectValue}) => {
    const {setSortCurrencyInvoice, currencyInvoice} = useSortCurrencyInvoice((state)=>state)

    
    const sort_currencies = [
        'All',
        'Tether',
        'Tron',
        'Ethereum',
        'PayPal'
    ]
    const handleChooseStatus = (item) => {
        setSortCurrencyInvoice(item)
    }
    
    return (
        <div className="mainFrame_lastActiviti-sort">
            {
                sort_currencies.map((item,index) =>
                <a 
                    key={index}
                    className={currencyInvoice === item ? 'active' : ''}
                    onClick={()=>handleChooseStatus(item)}
                >
                    {item}
                </a>)
            }
        </div>
    );
}

export default ValueFilter;
